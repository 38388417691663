<template>
  <div class="rule">
    <el-tabs v-model="activeName" class="el-nav">
      <el-tab-pane :label="'直播管理'" name="live">
        <el-form :model="MaterialsForm" ref="coursesForm" label-position="right" label-width="150px" class="addForm">
          <el-select v-model="MaterialsForm.options" placeholder="编号" class="search-type search-type_first" @change="choseOptions">
            <el-option v-for="item in nameList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-input v-model="MaterialsForm.title" placeholder="请输入查询条件" class="search-input"></el-input>
          <div class="">
            <span class="type_text">状态</span>
            <el-select v-model="MaterialsForm.live_status" placeholder="请选择" :clearable="true" class="search-type">
              <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>
          <div class="">
            <span class="type_text">显示状态</span>
            <el-select v-model="MaterialsForm.is_show" placeholder="请选择" :clearable="true" class="search-type">
              <el-option v-for="item in showTypeList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>

          <el-button type="primary" class="search-btn" @click="screenSubmit">查询</el-button>
          <el-button class="search-btn reset" @click="resetFun('coursesForm')">重置</el-button>
          <el-button type="warning" class="f-r search-add" @click="add()">新增直播</el-button>
        </el-form>
        <div class="num">
          共 <span>{{ total }}</span> 条数据
        </div>
        <div class="live-list" v-loading="loading" v-if="tableList.length > 0">
          <div class="item" :class="(index + 1) % 4 === 0 ? 'last' : ''" v-for="(item, index) in tableList" :key="index">
            <div class="cover">
              <img :src="item.cover_url" alt="" />
            </div>
            <div class="title-box">
              <p>{{ item.title }}</p>
            </div>
            <div class="time">开始时间：{{ item.start_time }}</div>
            <div class="tool">
              <div class="left">
                <el-tag class="blue" v-if="item.is_show === 1">发布</el-tag>
                <el-tag class="gray" v-if="item.is_show !== 1">关闭</el-tag>
                <el-tag class="green" v-if="item.live_status === 1">直播中</el-tag>
                <el-tag class="orange" v-if="item.live_status === 2">未开始</el-tag>
                <el-tag class="gray" v-if="item.live_status === 3">回放</el-tag>
                <el-tag class="cyan" v-if="item.live_status === 4">待审核</el-tag>
              </div>
              <div class="right">
                <el-dropdown @command="contentStatus($event, item)">
                  <span class="el-dropdown-link">
                    <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle" />
                    <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle" />
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1">查看</el-dropdown-item>
                    <el-dropdown-item command="2" v-if="item.is_show === 1">隐藏</el-dropdown-item>
                    <el-dropdown-item command="3" v-if="liveType(item)">编辑</el-dropdown-item>
                    <el-dropdown-item command="4" v-if="item.live_status === 2 || item.live_status === 4">取消</el-dropdown-item>
                    <el-dropdown-item command="5" v-if="item.is_show !== 1">显示 </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
        <empty-prompt style="margin-top: 100px" v-else></empty-prompt>
        <el-pagination
          class="page"
          background
          v-if="tableList.length !== 0 && total > 12"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          layout="prev, pager, next,total, jumper"
          :total="total"
        >
        </el-pagination>

        <el-dialog
          class="dialog"
          :title="dialogTitle"
          :visible.sync="dialogFormVisible"
          v-if="dialogFormVisible"
          top="calc( 50vh - 250px )"
          width="540px"
          :destroy-on-close="true"
          @close="close('from')"
          :close-on-click-modal="false"
        >
          <el-form label-position="left" :label-position="labelPosition" label-width="93px" class="form" ref="from" :rules="rules" :model="form">
            <el-form-item label="来源" prop="type">
              <el-select v-model="form.type" placeholder="请选择" @change="choseType" :disabled="id !== ''">
                <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主持人秘钥" prop="host_key" v-if="form.type === 'meeting'">
              <el-input v-model.trim="form.host_key" :disabled="id !== '' && isReplay" maxlength="6" placeholder="请输入主持人秘钥"></el-input>
            </el-form-item>
            <el-form-item label="会议主题" prop="title">
              <el-input v-model.trim="form.title" maxlength="80" placeholder="请输入会议主题"></el-input>
            </el-form-item>
            <el-form-item label="主办方" prop="organizer">
              <el-input v-model.trim="form.organizer" maxlength="40" placeholder="请输入主办方"></el-input>
            </el-form-item>
            <el-form-item label="上传封面图" prop="cover_url">
              <upload-file @successUrl="imgUrl" :limit="1" :folder="'discover'" :url="url" :default_value="new Array(form.cover_url ? form.cover_url : '')"></upload-file>
            </el-form-item>
            <el-form-item class="last_item" label="会议时间" prop="time">
              <el-date-picker
                :disabled="id !== '' && isReplay"
                :picker-options="pickerOptions"
                @change="timeChange"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="form.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="close('from')">取 消</el-button>
            <el-button v-if="isClick" class="form-operation-item" round type="primary" @click="preservation('from')">保存 </el-button>
            <el-button v-else class="form-operation-item" round type="primary">保存 <i class="el-icon-loading"></i></el-button>
          </span>
        </el-dialog>
        <!-- 隐藏弹框-->
        <el-dialog
          class="delDiolog"
          :visible.sync="isdelDialogVisible"
          top="calc( 50vh - 150px )"
          icon="el-icon-warning"
          :destroy-on-close="true"
          width="360px"
          center
          :close-on-click-modal="false"
          @close="closeDiog"
        >
          <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
          <span>你确定{{ showOrHiden }}该直播么？</span>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="closeDiog">取 消</el-button>
            <el-button class="form-operation-item" round type="primary" @click="putTagStatus">确 认</el-button>
          </span>
        </el-dialog>
        <!-- 取消弹框-->
        <el-dialog
          class="delDiolog"
          :visible.sync="isdcancelDialogVisible"
          top="calc( 50vh - 150px )"
          icon="el-icon-warning"
          :destroy-on-close="true"
          width="360px"
          center
          :close-on-click-modal="false"
          @close="closeDiogCancel"
        >
          <div class="dioTitle"><i class="el-icon-warning"></i> 确定取消该会议直播吗？</div>
          <div class="desc">
            <span class="theme">会议主题：{{ theme }}</span>
            <span>开始时间：{{ meeting_start_time }}</span>
          </div>
          <span slot="footer" class="dialog-footer form-operation">
            <el-button class="form-operation-item" round @click="closeDiogCancel">取 消</el-button>
            <el-button class="form-operation-item" round type="primary" @click="putTagStatusCancel">确 认</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import uploadFile from 'components/uploadFile';
import EmptyPrompt from 'components/EmptyPrompt';
import _ from 'lodash';
export default {
  props: {},
  data() {
    var checkNum = (rule, value, callback) => {
      let check = /^[0-9]*$/;
      if (value === '') {
        callback(new Error('请输入主持人秘钥'));
      } else if (!check.test(value) || value.length < 6) {
        callback(new Error('请输入6位数数字'));
      } else {
        callback();
      }
    };
    return {
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      isReplay: false,
      loading: false,
      labelPosition: 'right',
      url: 'image/browse',
      dialogFormVisible: false,
      isdelDialogVisible: false,
      isdcancelDialogVisible: false,
      isvideoDialogVisible: false,
      dialogTitle: '新增直播',
      total: 0,
      page: 1,
      pageSize: 12,
      activeName: 'live',
      showOrHiden: '隐藏',
      videoTitle: '',
      id: '',
      circle_uuid: '',
      meeting_start_time: '',
      theme: '',
      isClick: true,
      MaterialsForm: {
        options: 'title',
        title: '',
        is_show: '',
        live_status: '',
      },
      form: {
        title: '',
        organizer: '',
        time: [],
        cover_url: '',
        type: 'meeting',
        host_key: '',
      },
      start_time: '',
      end_time: '',
      sourceList: [
        {
          label: '腾讯会议',
          value: 'meeting',
        },
        {
          label: '其他平台',
          value: 'other',
        },
      ],
      nameList: [
        {
          label: '标题',
          value: 'title',
        },
      ],
      typeList: [
        {
          label: '直播中',
          value: 1,
        },
        {
          label: '未开始',
          value: 2,
        },
        {
          label: '回放',
          value: 3,
        },
        {
          label: '待审核',
          value: 4,
        },
      ],
      showTypeList: [
        {
          label: '发布',
          value: 1,
        },
        {
          label: '关闭',
          value: 0,
        },
      ],
      tableList: [],
      startTimeRange: '',
      endTimeRange: '',
      rules: {
        title: [{ required: true, message: '请输入会议主题', trigger: 'blur' }],
        type: [{ required: true, message: '请选择来源', trigger: 'change' }],
        host_key: [{ required: true, validator: checkNum, trigger: 'blur' }],
        source: [{ required: true, message: '请选择来源', trigger: 'change' }],
        time: [{ required: true, message: '请选择时间', trigger: 'change' }],
        cover_url: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
      },
    };
  },

  created() {
    this.circle_uuid = localStorage.getItem('circle-uuid');
    console.log(this.circle_uuid);
  },
  mounted() {
    this.getList();
  },
  methods: {
    choseType(val) {
      if (val === 'other') {
        this.form.host_key = '';
      }
    },
    liveType(item) {
      let startTime = new Date(item.start_time);
      let nowTime = new Date();
      return (startTime > nowTime && item.live_status === 4) || item.live_status === 3;
    },
    getList() {
      this.loading = true;
      let url = `${this.$api.meeting}?circle_uuid=${this.circle_uuid}&title=${this.MaterialsForm.title}&is_show=${this.MaterialsForm.is_show}&live_status=${this.MaterialsForm.live_status}&page=${this.page}&pageSize=${this.pageSize}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.loading = false;
          this.tableList = res.data.data.data;
          this.total = res.data.data.meta.total;
        }
      });
    },
    // 查询
    screenSubmit() {
      this.page = 1;
      this.getList();
    },
    // 重置
    resetFun() {
      this.MaterialsForm.title = '';
      this.MaterialsForm.is_show = '';
      this.MaterialsForm.live_status = '';
      this.page = 1;
      this.getList();
    },
    choseOptions(val) {
      this.MaterialsForm.options = val;
    },
    add() {
      this.dialogTitle = '新增直播';
      this.dialogFormVisible = true;
    },
    // 下拉
    contentStatus(command, item) {
      this.id = item.id;
      if (command === '1') {
        this.$router.push({
          path: `show/${item.id}`,
        });
      } else if (command === '2') {
        // 隐藏
        this.isdelDialogVisible = true;
        this.showOrHiden = '隐藏';
      } else if (command === '3') {
        // 编辑
        this.dialogTitle = '编辑直播';
        this.dialogFormVisible = true;
        this.form.title = item.title;
        this.form.organizer = item.organizer;
        this.form.cover_url = item.cover_url;
        this.form.type = item.type;
        this.form.host_key = item.host_key;
        this.form.time = [item.start_time, item.end_time];
        this.start_time = item.start_time;
        this.end_time = item.end_time;

        if (item.live_status === 3) {
          this.isReplay = true;
        } else {
          this.isReplay = false;
        }
      } else if (command === '4') {
        // 取消
        this.isdcancelDialogVisible = true;
        this.meeting_start_time = item.start_time;
        this.theme = item.title;
      } else {
        this.isdelDialogVisible = true;
        this.showOrHiden = '显示';
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    // 时间选择
    timeChange() {
      this.isClick = true;
      if (this.form.time === null) {
        this.start_time = '';
        this.end_time = '';
      } else {
        this.start_time = this.form.time[0];
        this.end_time = this.form.time[1];
      }
    },
    //上传图片
    imgUrl(data) {
      if (!data[data.length - 1]) {
        data[data.length - 1] = '';
      }
      this.form.cover_url = data[data.length - 1];
    },
    // 关闭弹框
    close(formName) {
      this.$refs[formName].resetFields();
      this.form = {
        title: '',
        organizer: '',
        time: [],
        cover_url: '',
        type: 'meeting',
        host_key: '',
      };
      this.id = '';
      this.isClick = true;
      this.dialogFormVisible = false;
    },
    // 保存
    preservation(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            host_key: this.form.host_key,
            type: this.form.type,
            title: this.form.title,
            organizer: this.form.organizer,
            cover_url: this.form.cover_url,
            start_time: this.start_time,
            end_time: this.end_time,
            circle_uuid: this.circle_uuid,
          };
          let url = '';
          this.isClick = false;
          if (this.id !== '') {
            url = `${this.$api.meeting}/${this.id}`;
            this.$http.put(url, data, true).then((res) => {
              this.isClick = true;
              if (res.data.success) {
                this.getList();
                this.close(formName);
                this.$message({
                  message: res.data.message,
                  type: 'success',
                });
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error',
                });
              }
            });
          } else {
            url = this.$api.meeting;
            console.log(data);
            this.$http.post(url, data, true).then((res) => {
              this.isClick = true;
              if (res.data.success) {
                this.getList();
                this.close(formName);
                this.$message({
                  message: res.data.message,
                  type: 'success',
                });
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error',
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },

    // 隐藏取消
    closeDiog() {
      this.isdelDialogVisible = false;
      this.id = '';
    },
    // 隐藏确认
    putTagStatus: _.debounce(function () {
      let url = `${this.$api.meeting}/${this.id}/${this.$api.show}`;
      let data = {};
      this.$http.post(url, data, true).then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          this.$message({
            message: '操作成功',
            type: 'success',
          });
          this.closeDiog();
          this.getList();
        }
      });
    }, 500),

    // 取消
    closeDiogCancel() {
      this.isdcancelDialogVisible = false;
      this.id = '';
    },
    // 取消确认
    putTagStatusCancel: _.debounce(function () {
      let url = `${this.$api.meeting}/${this.id}/${this.$api.cancel}`;
      let data = {};
      this.$http.post(url, data, true).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success',
          });
          this.closeDiogCancel();
          this.getList();
        }
      });
    }, 500),
    // 视频弹框关闭
    handleClose() {
      this.isvideoDialogVisible = false;
    },
  },
  components: {
    uploadFile,
    EmptyPrompt,
  },
};
</script>
<style lang="less" scoped>
@import '~assets/less/meeting/index.less';
</style>
